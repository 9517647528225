//TODO: refactoring

$grid-small: 8px;
$grid-half: 16px;
$grid-medium: 24px;
$grid: 32px;

.footer-modal {
    margin: -16px;
    // overflow: hidden;
    width: calc(100vw - #{0.02 * $grid});
    // width: 100%;
    // height: calc(100vh - #{2 * $grid});
    padding-bottom: $grid;
    // margin-bottom: 30px;
    border-radius: 10px;
    background: url('../../assets/innerPageBackground.png');
    background-size: cover;
    background-color: #fff;

    &__header {
      // width: calc(100vw - #{2 * $grid});
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $grid-half $grid;
      // position: fixed;
      // background: #fff;
      // height: 2 * $grid;
      padding-left: 50px;
      img {
        width: 15px;
        height: 15px;
        margin-top: $grid-small;
      }
      h4 {
        font-size: 24px;
        color: #000;
      }
    }
    &__header-section {
      padding: 0 $grid;
      p {
        margin: 0;
        margin-bottom: 16px;
      }
    }
  
    &__content {
      // position: absolute;
      // top: 96px;
      // height: calc(100vh - #{4 * $grid});
      // overflow-y: scroll;
      padding-left: 50px;
      padding-bottom: 30px;
      & > ol {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  
    ol:not(.alpha) {
      counter-reset: item;
      margin: 0;
      padding: $grid-small $grid;
      @media (max-width: 600px) {
        padding: 0 12px 0 0;
      }
    }
    ol:not(.alpha) > li {
      display: block;
      font-size: 14px;
      letter-spacing: 1px;
    }
    ol:not(.alpha) > li:before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      font-size: 16px;
      font-weight: bold;
    }
  
    ol.alpha {
      list-style-type: lower-alpha;
    }
    ol.alpha > li {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  